import booki from "../assets/images/booki.png";
import ohmyfood from "../assets/images/ohmyfood.png";
import sophiebluel from "../assets/images/sophiebluel.png";
import kasa from "../assets/images/kasa.png";

export const projects = [
    {
        id: '1',
        title: 'Booki',
        description: 'Booki',
        image: booki,
        tech: ["HTML", "CSS"],
        github: "https://github.com/Petochh/Projet-Booki-OCR",
        live: "http://nicolaspereira.fr/booki/",
        category: "formation",
    },
    {
        id: '2',
        title: 'Oh My Food',
        description: 'Oh My Food',
        image: ohmyfood,
        tech: ["HTML", "SCSS"],
        github: "https://github.com/Petochh/Projet-ohmyfood-OCR",
        live: "http://nicolaspereira.fr/ohmyfood/",
        category: "formation",
    },
    {
        id: '3',
        title: 'Sophie Bluel',
        description: 'Sophie Bluel',
        image: sophiebluel,
        tech: ["HTML", "SCSS", "JS"],
        github: "https://github.com/Petochh/Projet-Sophie-Bluel-OCR",
        live: "http://nicolaspereira.fr/sophiebluel/",
        category: "formation",
    },
    {
        id: '4',
        title: 'Kasa',
        dexription: 'Kasa',
        image: kasa,
        tech: ["HTML", "SCSS", "REACT"],
        github: "https://github.com/Petochh/Projet-Kasa-OCR",
        live: "http://nicolaspereira.fr/kasa/",
        category: "formation",
    },
]