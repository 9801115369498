import './components/styles/global.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/home/home";
import Error from "./pages/error/error";
import Secret from "./pages/secret/secret";

import NavBar from './components/navBar/navBar';
import Footer from './components/footer/footer';
import EasterEggListener from './components/easteregg/easteregg';

function App() {
    return (
        <Router>
            <EasterEggListener />
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Error />} />
                <Route path="/page-secrete" element={<Secret />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
