import React from 'react';
import './about.scss';
import aboutimg from '../../assets/images/about.jpg'

export default function About() {
    return (
        <section id="about" className="about">
        <div className="about__container">
            <div className="about__content">
            <div className="about__image">
                <img src={aboutimg} alt="image divers" />
            </div>
            <div className="about__text">
                <h2 className="about__text-title">
                À propos de <span>Moi</span>
                </h2>
                <p className="about__text-description">
                    Fort de plus de 10 ans de passion pour le développement web, j’ai entrepris de transformer cet intérêt en une carrière professionnelle en tant que développeur fullstack.<br />
                    En 2023, j’ai enrichi mes compétences en suivant une formation d’intégrateur web sur OpenClassrooms, où j’ai acquis une solide maîtrise des fondamentaux du développement front-end.<br />
                    Parallèlement, mon expertise en Python continue de s’affiner, et je prévois d’approfondir mes connaissances grâce à une formation avancée.<br />
                    Mon objectif est de concevoir des solutions robustes, innovantes et adaptées aux besoins des utilisateurs.
                </p>
            </div>
            </div>
        </div>
        </section>
    );
}