import React from 'react';
import { Mail, MapPin } from 'lucide-react';
import './contact.scss';

export default function Contact() {
    return (
        <section id="contact" className="contact">
            <div className="contact__container">
                <h2 className="contact__title">
                    Me <span>Contacter</span>
                </h2>
                <div className="contact__content">
                    <div className="contact__info">
                        <h3 className="contact__info-title">Prendre contact</h3>
                        <div className="contact__info-item">
                            <Mail size={24} />
                            <span>contact@example.com</span>
                        </div>
                        <div className="contact__info-item">
                            <MapPin size={24} />
                            <span>Villard de lans, France</span>
                        </div>
                    </div>
                    <form className="contact__form">
                        <input type="text" placeholder="Votre nom" aria-label="Votre nom" />
                        <input type="email" placeholder="Votre email" aria-label="Votre email" />
                        <textarea placeholder="Votre message" aria-label="Votre message" rows={4} ></textarea>
                        <button type="submit" disabled>
                            Envoyer
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
}