import React from 'react';
import './footer.scss';

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
        <div className="footer__container">
            <div className="footer__section">
                <p>© {currentYear} Nicolas Pereira. Tous droits réservés.</p>
            </div>
        </div>
        </footer>
    );
}