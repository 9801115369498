import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function EasterEggListener() {
    const navigate = useNavigate();
    const easterEggSequence = ['P', 'E', 'T', 'O', 'C', 'H', 'H'];
    const [userInput, setUserInput] = useState([]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const key = event.key.toUpperCase();
            setUserInput((prevInput) => [...prevInput, key].slice(-easterEggSequence.length));
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        if (userInput.join('') === easterEggSequence.join('')) {
            navigate('/page-secrete');
            setUserInput([]);
        }
    }, [userInput, navigate]);

    return null; // Ce composant ne rend rien
}

export default EasterEggListener;
