import React from "react"
import "./skills.scss"

import { skills } from "../../data/skills.js"

export default function Skills() {
    return (
        <section id="skills" className="skills">
            <div className="skills__container">
                <h2 className="skills__title">
                Mes <span>Compétences</span>
                </h2>
                <div className="skills__grid">
                {skills.map((skillGroup, index) => (
                    <div key={index} className="skills__card">
                    <h3 className="skills__card-title">{skillGroup.category}</h3>
                    <ul className="skills__card-list">
                        {skillGroup.items.map((skill, i) => (
                        <li key={i} className="skills__card-item">
                            {skill}
                        </li>
                        ))}
                    </ul>
                    </div>
                ))}
                </div>
            </div>
        </section>
    );
}