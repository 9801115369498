
import "./home.scss"

import Hero from "../../components/hero/hero.jsx"
import About from "../../components/about/about.jsx"
import MySkills from "../../components/skills/skills.jsx"
import ProjetCard from "../../components/projetCard/projetCard"
import FormContact from "../../components/contact/contact.jsx"



function Home(){
    return(
        <div className="home_container">
            <Hero />
            <About />
            <MySkills />
            <ProjetCard />
            <FormContact />
        </div>
    )
}

export default Home;