import './secret.scss'

function Secret(){
    return(
        <div className="secret_container">
            <h1>Page test</h1>
        </div>
    )
}

export default Secret;