import React, { useState } from 'react';
import { ExternalLink, Github } from 'lucide-react';
import './projetCard.scss';

import { projects } from "../../data/projects"

const categories = [
    { id: "all", label: "Tous" },
    { id: "formation", label: "Formations" },
    { id: "projet-perso", label: "Projets Perso" },
    { id: "projet-pro", label: "Projets Pro" }
];

export default function Projects() {
    const [activeCategory, setActiveCategory] = useState("all");

    const filteredProjects = projects.filter(
        project => activeCategory === "all" || project.category === activeCategory
    );

    return (
        <section id="projects" className="projects">
            <div className="projects__container">
            <h2 className="projects__title">
                Mes <span>Projets</span>
            </h2>
    
            <div className="projects__filters">
                {categories.map((category) => (
                <button
                    key={category.id}
                    onClick={() => setActiveCategory(category.id)}
                    className={`projects__filter ${
                    activeCategory === category.id ? 'projects__filter--active' : ''
                    }`}
                >
                    {category.label}
                </button>
                ))}
            </div>
    
            <div className="projects__grid">
                {filteredProjects.map((project, index) => (
                <article key={index} className="projects__card">
                    <div className="projects__card-image">
                    <img src={project.image} alt={project.title} />
                    </div>
                    <div className="projects__card-content">
                    <h3 className="projects__card-title">{project.title}</h3>
                    <p className="projects__card-description">{project.description}</p>
                    <div className="projects__card-tags">
                        {project.tech.map((tech, i) => (
                        <span key={i} className="projects__card-tag">
                            {tech}
                        </span>
                        ))}
                    </div>
                    <div className="projects__card-links">
                        <a href={project.github} target="_blank" rel="noopener noreferrer">
                        <Github size={20} />
                        </a>
                        <a href={project.live} target="_blank" rel="noopener noreferrer">
                        <ExternalLink size={20} />
                        </a>
                    </div>
                    </div>
                </article>
                ))}
            </div>
            </div>
        </section>
    );
}