import React from 'react';
import { ArrowDown } from 'lucide-react';
import './Hero.scss';

export default function Hero() {
    return (
        <section className="hero">
            <div className="hero__gradient">
            </div>
            <div className="hero__content">
                <h1 className="hero__title">
                <span>Bonjour</span>, je m'appelle<br />
                <span>Nicolas Pereira</span>
                </h1>
                <p className="hero__description">
                <span>Je suis</span> Developpeur web junior
                </p>
                <a href="#projects" className="hero__button">
                Voir mes projets
                </a>
            </div>
            <div className="hero__scroll-indicator">
                <ArrowDown size={32} />
            </div>
        </section>
    );
}